import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import HomeDesktop from "../../images/projects/labgrown/about--desktop.mp4"
import WorkInfo from "../../components/work-info"
import ProjectCard from "../../components/project-card"
import Versed from "../../images/projects/versed/cover.jpg"
import IsaLazo from "../../images/projects/isa-lazo/cover.jpg"

const aurisPage = () => (
  <Layout>
    <SEO title="Labgrown" />
    <WorkInfo 
      title="Labgrown"
      link="labgrown.co" 
      team1="Bailey Latimer"
      challenge="Jordan Mantzke is a content creator behind the popular Modern Goldsmith channel on YouTube. He wanted to evolve his engagement ring offering from custom built pieces to pre-made, mass-produced pieces. Each ring required several options – stone, metal, and size."
      solution="We worked closely together to deliver a warm, artisan experience at scale. For the product page, I created a custom app to render the ring in real time based on which materials were selected."/>
    <video  class="mb-6"
      loop
      muted
      autoPlay>
      <source src={HomeDesktop} type="video/mp4" />
    </video>

        <StaticImage class="mb-6" src="../../images/projects/labgrown/inspo.jpg" alt="Labgrown Moodboard" layout={"fullWidth"}/>
      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/labgrown/browser.jpg" alt="Labgrown Browser Preview" aspectRatio={3/2} layout={"fullWidth"}/>
        <StaticImage class="mb-6" src="../../images/projects/labgrown/home--about.jpg" alt="Labgrown About Home" aspectRatio={3/2} layout={"fullWidth"}/>
      </div>
      <StaticImage class="mb-6" src="../../images/projects/labgrown/home--desktop.jpg" alt="Labgrown Home Preview" layout={"fullWidth"}/>
      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/labgrown/browser.jpg" alt="Labgrown Browser Preview" layout={"fullWidth"} />
        <StaticImage class="mb-6" src="../../images/projects/labgrown/product--mobile.jpg" alt="Labgrown Product Page Mobile" layout={"fullWidth"}/>
      </div>
      <StaticImage class="mb-6" src="../../images/projects/labgrown/about--desktop.jpg" alt="Labgrown Moodboard" layout={"fullWidth"}/>

      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/labgrown/logo--before.jpg" alt="Labgrown Logo Before"  layout={"fullWidth"}/>
        <StaticImage class="mb-6" src="../../images/projects/labgrown/logo--after.jpg" alt="Labgrown Logo After"  layout={"fullWidth"}/>
      </div>

      <StaticImage class="mb-20"src="../../images/projects/labgrown/brand-01.jpg" alt="Labgrown Typography" layout={"fullWidth"}/>

      <div class="adv-gallery-v3 mb-20">
        <h4 className="color-contrast-higher mb-4 pl-8 lg:pl-0">Related</h4>
        <ul class="columns-1 lg:columns-2">
          <ProjectCard classes="" title="Versed Skin" url="versed-skin"  description="Revolutionizing the natural skincare industry with all-natural, vegan ingredients for cleansers, moisturizers, serums, masks." cover={Versed} role="Development" />

          <ProjectCard classes="" title="Isa Lazo" url="isa-lazo"  description="Clean skincare crafted from the purest elements of nature." cover={IsaLazo}  role="Development"/>
        </ul>
      </div>

  </Layout>
)

export default aurisPage
